import style from "./content.module.scss";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "next-i18next";
import Button from "../../Button/Button";
import HomeCard from "../HomeCard/HomeCard";
import PulseLoader from "react-spinners/PulseLoader";
import { GameContext } from "../../../context/GameContext";
import games from "../../../data/games/games";

const Content = ({ gameByCount }) => {

  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const {searchQuery,gamesToShow,handleClickBtn,handleFilterByCount,} = useContext(GameContext);

  useEffect(() => {
    gameByCount && handleFilterByCount(gameByCount).then(() => setLoading(false));
  }, [gameByCount, handleFilterByCount]);

  return (
    <section className={style["content"]} id="home-cards">
      <h3 className={style["content__title"]}>{t("home.contentTitle")}</h3>
      <p className={style["content__subtitle"]}>{t("home.contentSubtitle")}</p>
      <h4 className={style["content__game-title"]}>{searchQuery ? `${t("home.search.title").toUpperCase()} "${searchQuery}"` : t("home.gameSectionTitle")}</h4>
      {loading ? (
        <div className={style["content__cards-loader"]}>
          <PulseLoader
            css={{ marginTop: "50px", width: "70px", display: "flex", justifyContent: "space-between" }}
            color={"#1375a2"}
            size={10}
          />
        </div>
      ) : gamesToShow?.length !== 0 ? (
        <div className={style["content__cards"]}>
          {gamesToShow?.map((game, i) => (
            <HomeCard game={game} key={i} />
          ))}
        </div>
      ) : (
        <div className={style["content__no-result"]}>
          <p className={style["content__no-result__text"]}>{t("home.search.noResult")}</p>
        </div>
      )}
      {gamesToShow?.length !== games.length && (
        <div className={style["content__search-btn"]}>
          <Button
            text={t("home.search.btn")}
            padding="14px 20px"
            fontSize="16px"
            onClick={handleClickBtn}
          />
        </div>
      )}
    </section>
  );
};

export default Content;
